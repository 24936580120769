import React from 'react';

import { LAYOUT_TWO, URLS } from 'common/constants';
import { changeRoute } from 'common/utils';
import { useCategories } from '../../../../../../service/hooks';
import ProductCard from '../../../ProductCard';
import { usePersistedBranchId } from '../../../../../../common/hooks';
import { CategoryComponentProps } from '../../../../../../common/types';
import { StyledFashionCategoriesWrapper } from './style';

const FashionCategories: React.FC<CategoryComponentProps> = ({ isLoading }) => {
  const [branchId] = usePersistedBranchId();

  const { data: storeCategories, isLoading: isLoadingCategories } = useCategories({ branchId });

  const showLoadingCategories = isLoading || isLoadingCategories;

  if (showLoadingCategories) {
    return (
      <StyledFashionCategoriesWrapper>
        {Array.from([...Array(8)].keys()).map(num => (
          <ProductCard productLayout={LAYOUT_TWO} isLoading key={`sh-fashion-card-${num}`} />
        ))}
      </StyledFashionCategoriesWrapper>
    );
  }

  return (
    <StyledFashionCategoriesWrapper>
      {storeCategories?.map((category, index) => {
        const { uuid, showCategory } = category;
        return (
          showCategory && (
            <ProductCard
              index={index}
              data-testid={`${uuid}_fashion_category`}
              productLayout={LAYOUT_TWO}
              category={category}
              key={uuid}
              onClick={() =>
                changeRoute({ pathname: URLS.CATEGORY_PRODUCTS, query: { id: uuid } })
              }
            />
          )
        );
      })}
    </StyledFashionCategoriesWrapper>
  );
};

export default FashionCategories;
