import { LANGUAGE_AR } from 'common/constants';
import { AddressDetails, FormatAddressType, GetAddressDetailsType } from '../../modules/userModule/utils/types';

export const formatAddress: FormatAddressType = (address, t) => {
  const { block, street, building, unitType } = address || {};

  // TODO: trim address if it's very long
  let formattedAddress = '';
  if (block) formattedAddress += `${t('address:block')}: ${block}${t('address:comma')}`;
  if (street)
    formattedAddress += `${t('address:street')}: ${street}${building && unitType !== 'house' ? t('address:comma') : ''}`;
  if (building && unitType !== 'house') formattedAddress += `${t('address:building')}: ${building}`;

  return formattedAddress;
};

export const getAddressDetails: GetAddressDetailsType = (address, t, lang) => {
  const { covered } = address || {};

  const addressDetails: AddressDetails = getAddressText(address, t, lang);

  if (!covered) addressDetails.errorText = address ? t('weDontDeliverHere') : t('checkout:addAddressInfo');

  return addressDetails;
};

export const getAddressText: GetAddressDetailsType = (address, t, lang) => {
  const { area, areaName: areaFetchedName, title, unitType, unitNo } = address || {};
  const { titleAr, titleEn } = area || {};
  let areaName = '';
  if (titleAr || titleEn) areaName = lang === LANGUAGE_AR ? titleAr : titleEn;
  else if (areaFetchedName) areaName = areaFetchedName;
  const addressTitle = title || (unitType && t(unitType)) || t('address');
  const houseNumber = unitType && unitType === 'house' ? unitNo : '';
  const addressAreaName = areaName ? `- ${areaName}` : '';
  const formattedAddress = address ? formatAddress(address, t) : '';

  const addressDetails: AddressDetails = {
    title: `${addressTitle} ${houseNumber} ${addressAreaName}`,
    details: formattedAddress,
    fullAddress: `${addressTitle}  ${houseNumber} ${addressAreaName} | ${formattedAddress}`,
  };

  return addressDetails;
};
