import styled from 'styled-components';

export const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  & li {
    padding: ${({ theme }) => theme.space[5]}px 0;
    border-bottom: 1px solid rgb(245, 245, 245);
    border-top: 1px solid rgb(245, 245, 245);
    cursor: pointer;
    font-size: 17px;
    line-height: 1.41;
    text-transform: capitalize;
    font-weight: 400;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  & a {
    text-decoration: none;
    color: inherit;
    margin-inline-start: ${props => props.theme.space[2]}px;
  }
`;

export const StyledOtherLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
`;

export const StyledLink = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: ${({ theme }) => theme.space[3]}px;
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
`;

export const StyledLinkIcon = styled.div`
  width: 36px;
  height: 36px;
  margin: ${props => (props.theme.direction === 'ltr' ? '0 8px 0 0' : '0 0 0 8px')};
  padding: 6px;
  border-radius: 6px;
  background-color: ${props => props.theme.color.background};
`;

export const StyledUserSettingsWrapper = styled.a`
  margin: ${props => props.theme.space[5]}px 0;
`;
