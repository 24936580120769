import { CLIENT_TYPE } from 'common/constants';
import { QuerySupportedDeliveryZoneArgs, DeliveryZone } from '../../types/generated/deliveryzones_ms_index';
import { apiMutate } from '../../utils/mutation';
import { checkSupportedZoneQuery } from '../../queries/deliveryZones';

export type UseCheckSupportedZoneCallbackType = () => (
  input: QuerySupportedDeliveryZoneArgs,
) => Promise<{ supportedDeliveryZone: Partial<DeliveryZone>; hasError: boolean }>;

const useCheckSupportedZoneCallback: UseCheckSupportedZoneCallbackType = () => variables =>
  apiMutate({
    mutation: checkSupportedZoneQuery,
    variables: { ...variables },
    clientType: CLIENT_TYPE.DZMS_CLIENT,
    handler: (response: any) => {
      if (response?.supportedDeliveryZone) return { supportedDeliveryZone: response?.supportedDeliveryZone, hasError: false };
      return { supportedDeliveryZone: response?.supportedDeliveryZone, hasError: true };
    },
  });

export default useCheckSupportedZoneCallback;
