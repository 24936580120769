import styled from 'styled-components';

export const StyledCategoriesContainer = styled.div`
  position: sticky;
  z-index: 1;
  box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.1);
  ${({ theme, isManualOrder }) => `
    margin-bottom: ${theme.space[4]}px;
    background: ${theme.color.white};
    top: ${isManualOrder ? '0px' : '50px'};
    padding: ${theme.space[5]}px 0 ${theme.space[4]}px;
    @media (min-width: calc(${theme.screenBreakpoints.large} + 0.2px)) {
      top: 0;
    }`}
`;

export const StyledCategoriesTabsWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  & ::-webkit-scrollbar {
    display: none;
  }
  & > div {
    width: fit-content;
    padding: ${({ theme }) => `0 ${theme.space[3]}px`};
    @media (min-width: calc(${props => props.theme.screenBreakpoints.extraLarge} + 0.2px)) {
      padding: 0 ${({ theme }) => theme.space[3]}px;
    }
    & > div {
      & > span {
        display: flex;
      }
    }
  }
`;
