import React from 'react';

const IcWhatsapp: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5804 2.41365C12.8523 1.68523 11.9873 1.10802 11.0353 0.715232C10.0833 0.322445 9.06293 0.121843 8.03305 0.124975C3.71094 0.124975 0.1925 3.62583 0.190742 7.92966C0.18883 9.3001 0.550073 10.6466 1.2377 11.832L0.125 15.875L4.28223 14.7897C5.43297 15.4131 6.72113 15.7394 8.02988 15.7389H8.03305C12.3548 15.7389 15.8729 12.2377 15.875 7.93423C15.8776 6.90787 15.6761 5.89123 15.2822 4.94347C14.8882 3.99571 14.3097 3.13576 13.5804 2.41365ZM8.03305 14.4216H8.03023C6.86353 14.422 5.71804 14.1097 4.71289 13.5174L4.47488 13.3768L2.00797 14.0208L2.66645 11.627L2.51141 11.381C1.85904 10.3481 1.51341 9.15127 1.51473 7.92966C1.51473 4.35322 4.44008 1.44333 8.03551 1.44333C9.76027 1.44025 11.4156 2.12239 12.6375 3.33971C13.8594 4.55702 14.5477 6.20982 14.551 7.93458C14.5496 11.5114 11.6257 14.4216 8.03305 14.4216ZM11.6081 9.56337C11.4123 9.46564 10.4479 8.99384 10.2693 8.9288C10.0907 8.86376 9.95891 8.83107 9.82848 9.02654C9.69805 9.22201 9.32223 9.65935 9.20797 9.79119C9.09371 9.92302 8.97945 9.93744 8.78363 9.8397C8.58781 9.74197 7.95606 9.5363 7.20758 8.87185C6.62504 8.3547 6.23199 7.71626 6.11773 7.52115C6.00348 7.32603 6.10543 7.22021 6.20352 7.12318C6.29176 7.03564 6.39934 6.89537 6.49742 6.78146C6.59551 6.66755 6.6282 6.58599 6.69324 6.45591C6.75828 6.32583 6.72594 6.21193 6.67707 6.11454C6.6282 6.01716 6.23621 5.05704 6.07309 4.66646C5.91383 4.28607 5.75246 4.33775 5.63223 4.33177C5.51797 4.32615 5.38613 4.32474 5.25641 4.32474C5.15723 4.32732 5.05966 4.35032 4.96977 4.3923C4.87989 4.43428 4.79962 4.49434 4.73398 4.56873C4.55434 4.76419 4.04809 5.23669 4.04809 6.19576C4.04809 7.15482 4.75121 8.08294 4.84824 8.21302C4.94527 8.3431 6.22988 10.3129 8.19547 11.1577C8.56046 11.314 8.93339 11.4511 9.31273 11.5683C9.78207 11.7167 10.2092 11.696 10.5467 11.6457C10.9232 11.5898 11.7069 11.1739 11.8696 10.7183C12.0324 10.2626 12.0328 9.8724 11.9839 9.79119C11.935 9.70998 11.8043 9.66076 11.6081 9.56337Z"
      fill="black"
    />
  </svg>
);
export default IcWhatsapp;
