import { gql } from 'graphql-request';

const checkSupportedZoneQuery = gql`
  query ($restaurantReferenceId: ID!, $geoPoint: [Float!]!, $branchReferenceId: ID) {
    supportedDeliveryZone(
      restaurantReferenceId: $restaurantReferenceId
      geoPoint: $geoPoint
      branchReferenceId: $branchReferenceId
    ) {
      id
      geoShape
      branchReferenceId
      zoneName
      deliveryFee
      deliveryTime
      countryId
      minimumOrder
      restaurantReferenceId
      openingHours
      posExternalId
    }
  }
`;

export default checkSupportedZoneQuery;
