import React from 'react';
import { Icon } from '@zydalabs/storefront-components';
import useTranslation from 'next-translate/useTranslation';

import { logger } from 'common/utils';
import { NavLinksProps } from '../components/Layout/layout.types';
import { URLS } from '../constants';
import { IcLocation } from '../icons';
/**
 * Returns the store nav links to be rendered ready with localization and functionality.
 *
 * @param t
 * @param branchesCount
 *
 * @returns NavLinksProps
 */
const useStoreTabs = (branchesCount: number): NavLinksProps => {
  const navLinks: NavLinksProps = [];
  const { t } = useTranslation('layout');
  try {
    if (branchesCount > 0) {
      navLinks.push({
        title: <div>{`${t('branches')}`}</div>,
        icon: <Icon width="19px" icon={IcLocation} />,
        value: 'branches',
        url: URLS.BRANCHES,
      });
    }
  } catch (error) {
    logger.error({ error: 'Server Layout Translation Of Taps', extraData: error });
  }
  return navLinks;
};

export default useStoreTabs;
