import styled from 'styled-components';

export const StyledFashionCategoriesWrapper = styled.div`
  display: grid;
  margin-top: 0;
  grid-gap: ${props => props.theme.space[6]}px;
  grid-template-columns: repeat(2, 1fr);
  padding: ${props => props.theme.space[4]}px;

  @media screen and (min-width: calc(${props => props.theme.screenBreakpoints.medium} + 0.2px)) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: calc(${props => props.theme.screenBreakpoints.large} + 0.2px)) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
