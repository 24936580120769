import React from 'react';
import { useTheme } from '@zydalabs/storefront-components';
import useTranslation from 'next-translate/useTranslation';
import { Typography } from '@zydalabs/zac-react';

import { useLayout } from 'service/hooks';
import { StyledPoweredByZydaWrapper } from './styles';

const PoweredByZyda = () => {
  const theme = useTheme();
  const { t } = useTranslation('home');
  const { data: layoutSettings } = useLayout();
  const { showPoweredBy } = layoutSettings || {};

  return showPoweredBy ?
    <StyledPoweredByZydaWrapper href="https://www.zyda.com/en/" target="_blank" rel="noreferrer">
      <Typography variant="body14" color={theme.color.grey0} cursor="pointer">
        {t('poweredByZyda')}
      </Typography>
    </StyledPoweredByZydaWrapper> : null;
};

export default PoweredByZyda;
