import { AddressWithDeliveryZone } from 'common/types';
import { showAddressQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import { Address, Scalars } from '../../types/generated';
import { useQuery } from '../../utils';
import ServiceConfigs from '../../config';
import { useCheckSupportedZoneCallback } from '../deliveryZones';

type UseShowAddressType = ({
  addressId,
  initialData,
}?: {
  addressId?: Scalars['ID'];
  initialData?: Address;
}) => QueryHookResponse<Partial<AddressWithDeliveryZone>>;

const useShowAddress: UseShowAddressType = ({ addressId, initialData } = {}) => {
  const checkSupportedZone = useCheckSupportedZoneCallback();
  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();
  const storeId = ServiceConfigs?.getStoreId();
  const loggedIn = !!ServiceConfigs.getUserToken();

  return useQuery<Partial<Address>>({
    query: showAddressQuery,
    variables: addressId && loggedIn ? { addressId, subdomain: ServiceConfigs.getSubDomain() } : null,
    requestHeaders: { ...ServiceConfigs.getAuthorizationHeader() },
    handler: (res: any) => {
      const address = res.showAddress;
      const waitForCheckSupportedZone = async () => {
        const supportedZone = await checkSupportedZone({
          restaurantReferenceId: storeId,
          geoPoint: [parseFloat(address?.lng), parseFloat(address?.lat)],
        });
        address.deliveryZone = supportedZone?.supportedDeliveryZone;
        address.covered = !!supportedZone?.supportedDeliveryZone;
        return address;
      };
      if (useDeliveryzonesMs) {
        if (address?.lat && address?.lng) return waitForCheckSupportedZone();
        return { ...address, covered: false };
      }
      return address;
    },
    config: { initialData },
  });
};

export default useShowAddress;
