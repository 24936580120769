import React, { useEffect, useRef, useState } from 'react';
import { Tabs, ShimmerComponent, useTheme } from '@zydalabs/storefront-components';
import useTranslation from 'next-translate/useTranslation';
import { Typography, useTheme as useZacTheme, Button } from '@zydalabs/zac-react';
import { LANGUAGE_AR, LAYOUT_TWO, URLS } from 'common/constants';
import { CategoryComponentProps } from 'common/types';
import { handleScheduledTime, cartItemDetails, sanitizeTitleString } from 'modules/productsModule/utils';
import { useCategoriesAndProducts, useSelectedCartItems } from 'service/hooks';
import { changeRoute } from 'common/utils';
import {
  usePersistedBranchId,
  usePersistedCartData,
  useResponsive,
  useGlobalOrderTypeAndTime,
  useIsMobile,
} from 'common/hooks';
import { ChevronRightIcon, ChevronLeftIcon } from '@zydalabs/zac-icons-react';
import { useShouldSortProductsByLang } from '../../../../hooks';
import ProductCard from '../../../ProductCard';
import CategoriesScrollObserverWrapper from '../CategoriesScrollObserverWrapper';
import { StyledCategoriesTabsWrapper, StyledCategoriesContainer } from '../style';
import {
  StyledGroceryCardRow,
  StyledGroceryCategory,
  StyledViewProductsBtnWrapper,
  StyledGroceryProductsContainer,
  StyledGroceryCategoryContainer,
  StyledGroceryCategoryProductsWrapper,
} from './style';

const GroceryCategories: React.FC<CategoryComponentProps> = ({ isLoading }) => {
  const [branchId] = usePersistedBranchId();
  const [cartData] = usePersistedCartData();
  const { t, lang } = useTranslation('layout');
  const theme: any = useTheme();
  const { colors, spacing }: any = useZacTheme();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [isObserverActive, setIsObserverActive] = useState(true);
  const groceryCategoriesBar = useRef(null);
  const isMobile = useIsMobile();
  const isLargeScreen = useResponsive(`min-width: ${theme.screenBreakpoints.extraLarge}`);
  const shouldSortProductsByLanguage = useShouldSortProductsByLang(lang);
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const { fulfillmentTimeType, scheduleSlotInterval } = globalOrderTypeAndTime || {};
  const scheduledTime = handleScheduledTime(fulfillmentTimeType, scheduleSlotInterval);
  const { data: categories, isLoading: isLoadingCategoriesAndProducts } = useCategoriesAndProducts({
    branchId,
    offsetAttributes: {
      limit: 4,
      offset: 0,
    },
    ...(!!scheduledTime && { scheduledTime }),
    lang: shouldSortProductsByLanguage ? lang : null,
  });

  const { data: selectedCartItems } = useSelectedCartItems({ ...cartData });

  const isArabic = lang === LANGUAGE_AR;

  const showLoadingCategories = isLoading || isLoadingCategoriesAndProducts;

  const arrowedIcon = isArabic ? (
    <ChevronLeftIcon color={colors?.positiveAccent?.secondary} width={spacing['20']} />
  ) : (
    <ChevronRightIcon color={colors?.positiveAccent?.secondary} width={spacing['20']} />
  );

  // TODO: Remove repeated code with Food Categories in a wrapper component
  const categoriesTabs: Array<Record<string, string>> = categories
    ?.filter(category => category.published && category.showCategory)
    .map(category => ({
      name: isArabic ? sanitizeTitleString(category.titleAr) : sanitizeTitleString(category.titleEn),
      tabValue: category.uuid,
    }));

  if (!selectedCategoryId && !isLoadingCategoriesAndProducts && categoriesTabs?.[0]?.tabValue) {
    setSelectedCategoryId(categoriesTabs[0].tabValue);
  }

  const categoryTabsRefs = categoriesTabs?.reduce((refsObj, category) => {
    const newRefsObj = { ...refsObj, [category?.tabValue]: category.name };
    return newRefsObj;
  }, {});

  let scrollableContent;
  useEffect(() => {
    // TODO: Change all accessing dom elements by document.getElementById to refs
    scrollableContent = document.getElementById('scrollable-content');
  });

  const scrollCategoriesHorizontally = categoryId => {
    setSelectedCategoryId(categoryId);
    // TODO: Change all accessing dom elements by document.getElementById to refs
    const selectedCategoryTab = document.getElementById(categoryTabsRefs[categoryId]);
    const paddingOffset = isLargeScreen ? 32 : 16;

    if (groceryCategoriesBar && groceryCategoriesBar.current) {
      groceryCategoriesBar.current.scrollLeft = isArabic
        ? selectedCategoryTab?.offsetLeft + selectedCategoryTab?.offsetWidth - scrollableContent?.offsetWidth + paddingOffset
        : selectedCategoryTab?.offsetLeft - paddingOffset;
    }
  };

  const handleCategoryClick = categoryId => {
    if (categoryId) {
      const productsAnchorTarget = document.getElementById(categoryId);
      const productsAnchorYOffset = productsAnchorTarget?.getBoundingClientRect().top + window.pageYOffset - 110;
      setIsObserverActive(false);
      scrollCategoriesHorizontally(categoryId);
      if (isMobile) window.scrollTo({ top: productsAnchorYOffset, behavior: 'smooth' });
      else
        scrollableContent?.scroll({
          top: productsAnchorTarget.offsetTop - 80,
          behavior: 'smooth',
        });
    }
  };

  if (showLoadingCategories) {
    return (
      <>
        <StyledCategoriesContainer>
          <StyledCategoriesTabsWrapper>
            <Tabs tabs={[...Array(6)].map((_, index) => ({ tabValue: index.toString() }))} isLoading />
          </StyledCategoriesTabsWrapper>
        </StyledCategoriesContainer>
        <StyledGroceryProductsContainer>
          {Array.from([...Array(6)].keys()).map(rowNum => (
            <div key={rowNum}>
              <ShimmerComponent.ShimmerEffect>
                <ShimmerComponent.NoAnimationBox width="100%" justifyContent="space-between" alignItems="center">
                  <ShimmerComponent.Rect width="40%" height={30} m={4} />
                  <ShimmerComponent.Rect width="20%" height={20} m={4} />
                </ShimmerComponent.NoAnimationBox>
              </ShimmerComponent.ShimmerEffect>
              <StyledGroceryCardRow>
                {Array.from([...Array(3)].keys()).map(num => (
                  <ProductCard productLayout={LAYOUT_TWO} isLoading key={`sh-grocery-card-${num}`} />
                ))}
              </StyledGroceryCardRow>
            </div>
          ))}
        </StyledGroceryProductsContainer>
      </>
    );
  }

  return (
    <>
      <StyledCategoriesContainer>
        <StyledCategoriesTabsWrapper ref={groceryCategoriesBar}>
          <Tabs tabs={categoriesTabs} value={selectedCategoryId} variant="category" showHeader onChange={handleCategoryClick} />
        </StyledCategoriesTabsWrapper>
      </StyledCategoriesContainer>
      <StyledGroceryProductsContainer>
        {categories
          ?.filter(category => category.published && category.showCategory)
          ?.map(category => (
            <CategoriesScrollObserverWrapper
              key={category.uuid}
              uuid={category.uuid}
              selectedCategoryId={selectedCategoryId}
              isObserverActive={isObserverActive}
              setIsObserverActive={setIsObserverActive}
              scrollCategoriesHorizontally={scrollCategoriesHorizontally}
            >
              <StyledGroceryCategoryContainer>
                <StyledGroceryCategory>
                  <Typography
                    testId={`${category.uuid}_grocery_category`}
                    variant={isLargeScreen ? 'headingExpressive32' : 'headingExpressive20'}
                    color={colors?.positiveAccent?.primary}
                  >
                    {isArabic ? sanitizeTitleString(category.titleAr) : sanitizeTitleString(category.titleEn)}
                  </Typography>
                  <StyledViewProductsBtnWrapper>
                    <Button
                      variant="ghost"
                      onClick={() => changeRoute({ pathname: URLS.CATEGORY_PRODUCTS, query: { id: category.uuid } })}
                      text={t('showMore')}
                      endIcon={arrowedIcon}
                    />
                  </StyledViewProductsBtnWrapper>
                </StyledGroceryCategory>
                <StyledGroceryCategoryProductsWrapper>
                  {category.products.map((product, index) => {
                    const { uuid } = product;
                    const cartItem = cartItemDetails({ selectedCartItems, uuid });
                    return (
                      <ProductCard
                        index={index}
                        key={uuid}
                        productLayout={LAYOUT_TWO}
                        isGroceryCategory
                        uuid={uuid}
                        cartItem={cartItem}
                        onClick={() =>
                          changeRoute({
                            pathname: URLS.PRODUCT_DETAILS,
                            query: { id: uuid },
                          })
                        }
                      />
                    );
                  })}
                </StyledGroceryCategoryProductsWrapper>
              </StyledGroceryCategoryContainer>
            </CategoriesScrollObserverWrapper>
          ))}
      </StyledGroceryProductsContainer>
    </>
  );
};

export default GroceryCategories;
