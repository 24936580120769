import React from 'react';

import { RenderingRegistry } from 'common/lib/registries';
import { CategoryComponentProps, categoryComponentMapType } from '../../../../common/types';
import FoodCategories from './components/FoodCategories';
import FashionCategories from './components/FashionCategories';
import GroceryCategories from './components/GroceryCategories';
import { LAYOUT_ONE, LAYOUT_THREE, LAYOUT_TWO } from '../../../../common/constants';

const categoryComponentMap: categoryComponentMapType = {
  [LAYOUT_ONE]: FoodCategories,
  [LAYOUT_TWO]: FashionCategories,
  [LAYOUT_THREE]: GroceryCategories,
};

const Categories: React.FC<CategoryComponentProps> = props => {
  const layout = RenderingRegistry.getShowLayout();

  const Comp = categoryComponentMap[layout.categoryLayout];
  return <Comp {...props} />;
};
export default Categories;
