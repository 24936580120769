import React from 'react';
import { ShimmerComponent } from '@zydalabs/storefront-components';
import { StoreBrandInfoMobile } from './style';

const StoreBrandInfoMobileLoading = () => (
  <>
    <StoreBrandInfoMobile>
      <ShimmerComponent.ShimmerEffect>
        <ShimmerComponent.NoAnimationBox alignItems="center" mt={0} mb={0}>
          <ShimmerComponent.Circle radius="72" />
          <ShimmerComponent.NoAnimationBox flexDirection="column">
            <ShimmerComponent.Rect height={22} mx={4} mb={2} />
            <ShimmerComponent.Rect height={14} mx={4} />
          </ShimmerComponent.NoAnimationBox>
        </ShimmerComponent.NoAnimationBox>
      </ShimmerComponent.ShimmerEffect>
    </StoreBrandInfoMobile>
  </>
);

export default StoreBrandInfoMobileLoading;
