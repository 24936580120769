import { cache } from 'swr';
import { useQuery } from '../../utils';
import ServiceConfigs from '../../config';
import { storeCategoriesAndProductsQuery } from '../../queries/products';
import { Category, CategoryProductsArgs, Product, Scalars } from '../../types/generated';
import { QueryHookResponse, Override } from '../../types/internal';

type CategoryResponse = Override<Partial<Category>, { products: Array<Partial<Product>> }>;

type useCategoriesAndProductsTypes = ({
  branchId,
  initialData,
  offsetAttributes,
  scheduledTime,
  lang,
}: {
  branchId?: Scalars['ID'];
  initialData?: Array<CategoryResponse>;
  offsetAttributes?: CategoryProductsArgs['offsetAttributes'];
  scheduledTime?: string;
  lang?: string;
}) => QueryHookResponse<Array<CategoryResponse>>;

const useCategoriesAndProducts: useCategoriesAndProductsTypes = ({
  branchId,
  offsetAttributes,
  initialData,
  lang,
  scheduledTime,
}) =>
  useQuery<Array<CategoryResponse>>({
    query: storeCategoriesAndProductsQuery,
    variables: {
      subdomain: ServiceConfigs.getSubDomain(),
      branchId,
      offsetAttributes,
      ...(scheduledTime && { scheduledTime }),
    },
    requestHeaders: lang ? { LANGUAGE: lang } : null,
    handler: (res: Record<string, Array<Category>>) => {
      if (res?.categories?.length > 0) {
        const { categories } = res;
        const mappedCategories = categories.map(category => {
          const { products } = category;
          const returnedProducts = products?.map(product => {
            cache.set(product.uuid, product);
            return { uuid: product.uuid, quickAddToCart: product.quickAddToCart };
          });
          return { ...category, products: returnedProducts };
        });
        return mappedCategories;
      }
      return [];
    },
    config: { initialData },
  });

export default useCategoriesAndProducts;
