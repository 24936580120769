import { gql } from 'graphql-request';

const storeCategoriesAndProductsQuery = gql`
  query ($subdomain: String!, $branchId: ID, $offsetAttributes: OffsetAttributes, $scheduledTime: ISO8601DateTime) {
    categories(subdomain: $subdomain, branchId: $branchId) {
      uuid
      titleEn
      titleAr
      published
      showCategory
      products(branchId: $branchId, offsetAttributes: $offsetAttributes) {
        uuid
        titleEn
        titleAr
        photoUrl
        maxPrepTime
        descriptionAr
        descriptionEn
        displayPrice
        fulfillmentMode
        highestDiscountedPercentage
        price
        variantsTitleEn
        variantsTitleAr
        specialNotesEnabled
        specialNotesRequired
        specialNotesPlaceholderAr
        specialNotesPlaceholderEn
        quickAddToCart
        publishedBranchIds
        categoryIds
        variants {
          id
          price
          prepTime
          discountEnabled
          discountedPrice
          maximumQuantity
          stockData(scheduledTime: $scheduledTime) {
            unavailable
            stockCount
            trackingType
          }
        }
      }
    }
  }
`;

export default storeCategoriesAndProductsQuery;
