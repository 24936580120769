import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export type CategoriesScrollObserverWrapperProps = {
  children: React.ReactNode;
  uuid: string;
  selectedCategoryId: string;
  isObserverActive: boolean;
  setIsObserverActive: (isActive: boolean) => void;
  scrollCategoriesHorizontally: (id: string) => void;
};

const CategoriesScrollObserverWrapper: React.FC<CategoriesScrollObserverWrapperProps> = ({
  children,
  uuid,
  selectedCategoryId,
  isObserverActive,
  setIsObserverActive,
  scrollCategoriesHorizontally,
}) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
    rootMargin: '-30% 0px -70% 0px',
  });

  useEffect(() => {
    if (isObserverActive) {
      if (inView && entry?.isIntersecting) {
        scrollCategoriesHorizontally(uuid);
      }
    } else if (selectedCategoryId === uuid) {
      setIsObserverActive(true);
    }
    // TODO: Check best solution for dependency array linter complain
  }, [inView]);

  return (
    <div ref={ref} id={uuid}>
      {children}
    </div>
  );
};

export default CategoriesScrollObserverWrapper;
