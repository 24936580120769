import styled from 'styled-components';

export const StyledOrderModeWrapper = styled.div`
  ${({ theme }) => `
    background-color: ${theme.color.white};
  `}
`;

export const StyledSearchWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 0 ${props => props.theme.space[4]}px;
  @media (min-width: calc(${props => props.theme.screenBreakpoints.extraLarge} + 0.2px)) {
    padding: 0 ${({ theme }) => theme.space[4]}px;
  }
  & input {
    cursor: text;
  }
`;

export const StoreBrandInfoMobile = styled.div<{ centerImage: boolean; logoUrl: boolean }>`
  ${({ theme, centerImage, logoUrl }) => `
    display: none;
    justify-content: flex-start;
    background: ${theme.color.white};
    @media (max-width: ${theme.screenBreakpoints.tablet}) {
      display: flex;
      ${
        centerImage
          ? `
        justify-content: center;
        position: relative;
        z-index: 1;
        
        ${logoUrl && `margin-top: -${theme.space[15]}px;`};
        background-color: transparent;
        `
          : `
        padding: ${theme.space[4]}px 0 0;
        margin: 0 ${theme.space[4]}px;
        `
      };
    }
  `}
`;

export const StoreBrandInfoMobileWrapper = styled.div<{ logoUrl: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  ${({ logoUrl }) =>
    !logoUrl &&
    ` 
    align-items: center;
  `}
`;

export const StyledProductsShimmerWrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.space[4]}px;
    background-color: ${theme.color.white};
    min-height: 100vh;
  `}
`;

export const StyledLogoImageWrapper = styled.div<{ hideStoreName: boolean; logoRounded: boolean }>`
  flex-shrink: 0;
  position: relative;
  ${({ hideStoreName }) => (hideStoreName ? `width: 120px; height: 120px;` : `width: 64px; height: 64px;`)};
  & img {
    border-radius: ${({ logoRounded }) => (logoRounded ? `50%` : `0`)};
  }
`;
