import React, { useState } from 'react';
import { Typography } from '@zydalabs/zac-react';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';

import { StyledCashBackTextWrapper, StyledCashBackWrapper, StyledEmojiWrapper } from './styles';

const CashbackActionSheetComponent = dynamic(() => import('../CashbackActionSheet'), { ssr: false });

const CashbackSection: React.FC = () => {
  const { t } = useTranslation('common');

  const [isCashBackActionSheetOpen, setIsCashBackActionSheetOpen] = useState(false);

  return (
    <>
      <StyledCashBackWrapper onClick={() => setIsCashBackActionSheetOpen(true)}>
        <StyledCashBackTextWrapper>
          <Typography variant="heading16" mb={4} cursor="pointer">
            {t('getCashback')}
          </Typography>
          <Typography cursor="pointer" variant="body14">
            {t('earnCashback')}
          </Typography>
        </StyledCashBackTextWrapper>
        <StyledEmojiWrapper>
          <Typography cursor="pointer" variant="heading40">
            🤩
          </Typography>
        </StyledEmojiWrapper>
      </StyledCashBackWrapper>
      <CashbackActionSheetComponent
        isCashBackActionSheetOpen={isCashBackActionSheetOpen}
        setIsCashBackActionSheetOpen={setIsCashBackActionSheetOpen}
      />
    </>
  );
};

export default CashbackSection;
