import React, { useEffect, useRef, useState } from 'react';
import { Tabs, useTheme } from '@zydalabs/storefront-components';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Typography, useTheme as useZacTheme } from '@zydalabs/zac-react';
import { useCategoriesAndProducts, useSelectedCartItems } from 'service/hooks';
import { IcEmpty } from 'common/icons';
import { LANGUAGE_AR, LAYOUT_ONE, URLS } from 'common/constants';
import { CategoryComponentProps } from 'common/types';
import {
  usePersistedBranchId,
  useResponsive,
  usePersistedCartData,
  useGlobalOrderTypeAndTime,
  useIsMobile,
  useGlobalSnackBar,
  useGlobalProductDetails,
} from 'common/hooks';
import { sanitizeTitleString, handleScheduledTime } from 'modules/productsModule/utils';
import { RenderingRegistry } from 'common/lib/registries';
import { changeRoute, replaceRoute } from 'common/utils';
import ProductCard from '../../../ProductCard';
import { useShouldSortProductsByLang } from '../../../../hooks';
import { StyledCategoriesTabsWrapper, StyledCategoriesContainer } from '../style';
import { StyledCategoryTitleContainer } from './style';
import CategoriesScrollObserverWrapper from '../CategoriesScrollObserverWrapper';

const EmptyStateComponent = dynamic(() => import('common/components/EmptyState'));

const FoodCategories: React.FC<CategoryComponentProps> = ({ isLoading, defaultPrepTime }) => {
  const router = useRouter();
  const { t, lang } = useTranslation('product');
  const [branchId] = usePersistedBranchId();
  const theme: any = useTheme();
  const { colors }: any = useZacTheme();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [isObserverActive, setIsObserverActive] = useState(true);
  const [lastVisitedCateogryId, setLastVisitedCateogryId] = useState<string | string[]>();
  const foodCategoriesBar = useRef(null);
  const shouldSortProductsByLanguage = useShouldSortProductsByLang(lang);
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const [snackBarDetails, setSnackBarDetails] = useGlobalSnackBar();
  const [, setGlobalProductDetails] = useGlobalProductDetails();
  const { categoryId: scrolledToCategoryId, ...restOfTheQuery } = router?.query;

  const { fulfillmentTimeType, scheduleSlotInterval } = globalOrderTypeAndTime || {};
  const scheduledTime = handleScheduledTime(fulfillmentTimeType, scheduleSlotInterval);
  const isManualOrder = RenderingRegistry.getIsManualOrder();
  const { data: categories, isLoading: isLoadingCategoriesAndProducts } = useCategoriesAndProducts({
    branchId,
    lang: shouldSortProductsByLanguage ? lang : null,
    ...(!!scheduledTime && { scheduledTime }),
  });
  const [cartData] = usePersistedCartData();
  const { data: selectedCartItems } = useSelectedCartItems({ ...cartData });

  const isMobile = useIsMobile();
  const isLargeScreen = useResponsive(`min-width: ${theme.screenBreakpoints.extraLarge}`);
  const isArabic = lang === LANGUAGE_AR;
  // TODO: Remove repeated code with Grocery Categories in a wrapper component
  const categoriesTabs: Array<Record<string, string>> = categories
    ?.filter(category => category.published && category.showCategory)
    .map(category => ({
      name: isArabic ? sanitizeTitleString(category.titleAr) : sanitizeTitleString(category.titleEn),
      tabValue: category.uuid,
    }));

  if (!selectedCategoryId && !isLoadingCategoriesAndProducts && categoriesTabs?.[0]?.tabValue) {
    setSelectedCategoryId(categoriesTabs[0].tabValue);
  }

  const showLoadingProducts = isLoading || isLoadingCategoriesAndProducts;

  const categoryTabsRefs = categoriesTabs?.reduce((refsObj, category) => {
    const newRefsObj = { ...refsObj, [category?.tabValue]: category.name };
    return newRefsObj;
  }, {});

  let scrollableContent;
  useEffect(() => {
    // TODO: Change all accessing dom elements by document.getElementById to refs
    scrollableContent = document.getElementById('scrollable-content');
  });

  const scrollCategoriesHorizontally = categoryId => {
    setSelectedCategoryId(categoryId);
    // TODO: Change all accessing dom elements by document.getElementById to refs
    const selectedCategoryTab = document.getElementById(categoryTabsRefs?.[categoryId]);
    const paddingOffset = isLargeScreen ? 32 : 16;

    if (foodCategoriesBar && foodCategoriesBar.current) {
      foodCategoriesBar.current.scrollLeft = isArabic
        ? selectedCategoryTab?.offsetLeft + selectedCategoryTab?.offsetWidth - scrollableContent?.offsetWidth + paddingOffset
        : selectedCategoryTab?.offsetLeft - paddingOffset;
    }
  };

  const handleCategoryClick = categoryId => {
    if (categoryId) {
      const productsAnchorTarget = document.getElementById(categoryId);
      const productsAnchorYOffset = productsAnchorTarget?.getBoundingClientRect().top + window.pageYOffset - 110;
      setIsObserverActive(false);
      scrollCategoriesHorizontally(categoryId);
      if (isMobile) window.scrollTo({ top: productsAnchorYOffset, behavior: 'smooth' });
      else
        scrollableContent?.scroll({
          top: productsAnchorTarget?.offsetTop - 80,
          behavior: 'smooth',
        });
    }
  };

  useEffect(() => {
    if (scrolledToCategoryId) {
      setGlobalProductDetails({});
      setLastVisitedCateogryId(scrolledToCategoryId);
      replaceRoute({ pathname: URLS.HOME, query: restOfTheQuery }, { shallow: true });
    }
  }, [scrolledToCategoryId]);

  useEffect(() => {
    if (lastVisitedCateogryId) {
      handleCategoryClick(lastVisitedCateogryId);
      setSnackBarDetails({
        show: true,
        setShowSnackBar: () => snackBarDetails.setShowSnackBar(true),
        autoHide: true,
        message: t('itemNotPublished'),
        onHide: () =>
          setSnackBarDetails({
            show: false,
            setShowSnackBar: () => snackBarDetails.setShowSnackBar(false),
          }),
      });
    }
  }, [categories, lastVisitedCateogryId]);

  if (showLoadingProducts)
    return (
      <>
        <StyledCategoriesContainer isManualOrder={isManualOrder} key="loadingFoodCategories">
          <StyledCategoriesTabsWrapper>
            <Tabs tabs={[...Array(6)].map((_, index) => ({ tabValue: index.toString() }))} isLoading />
          </StyledCategoriesTabsWrapper>
        </StyledCategoriesContainer>
        <div key="loadingFoodProducts">
          {Array.from([...Array(6)].keys()).map(num => (
            <ProductCard productLayout={LAYOUT_ONE} isLoading key={`sh-food-card-${num}`} />
          ))}
        </div>
      </>
    );

  return (
    <>
      <StyledCategoriesContainer isManualOrder={isManualOrder}>
        <StyledCategoriesTabsWrapper ref={foodCategoriesBar}>
          <Tabs tabs={categoriesTabs} value={selectedCategoryId} variant="category" showHeader onChange={handleCategoryClick} />
        </StyledCategoriesTabsWrapper>
      </StyledCategoriesContainer>
      <>
        {categories.length === 0 ? (
          <EmptyStateComponent icon={IcEmpty} iconColor={theme.color.primary} subTitle={t('noProducts')} />
        ) : (
          categories
            ?.filter(category => category.published && category.showCategory)
            .map(category => (
              <CategoriesScrollObserverWrapper
                key={category.uuid}
                uuid={category.uuid}
                selectedCategoryId={selectedCategoryId}
                isObserverActive={isObserverActive}
                setIsObserverActive={setIsObserverActive}
                scrollCategoriesHorizontally={scrollCategoriesHorizontally}
              >
                <StyledCategoryTitleContainer>
                  <Typography
                    testId={`${category.uuid}_food_category`}
                    variant={isLargeScreen ? 'headingExpressive32' : 'headingExpressive20'}
                    color={colors?.positiveAccent?.primary}
                  >
                    {isArabic ? sanitizeTitleString(category.titleAr) : sanitizeTitleString(category.titleEn)}
                  </Typography>
                </StyledCategoryTitleContainer>
                {category.products.map((product, index) => {
                  const { uuid } = product;
                  const cartItem = selectedCartItems?.find(
                    ({ menuItem }) => menuItem.uuid === uuid || menuItem.hiddenIdentifier === uuid,
                  );
                  return (
                    <ProductCard
                      index={index}
                      uuid={uuid}
                      defaultPrepTime={defaultPrepTime}
                      productLayout={LAYOUT_ONE}
                      key={uuid}
                      cartItem={cartItem}
                      onClick={() =>
                        changeRoute({
                          pathname: URLS.PRODUCT_DETAILS,
                          query: { id: uuid },
                        })
                      }
                    />
                  );
                })}
              </CategoriesScrollObserverWrapper>
            ))
        )}
      </>
    </>
  );
};

export default FoodCategories;
