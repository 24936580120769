import { useQuery } from '../../utils';
import ServiceConfigs from '../../config';
import { storeCategoriesQuery } from '../../queries/products';
import { Category, Scalars } from '../../types/generated';
import { QueryHookResponse } from '../../types/internal';

type useCategoriesTypes = ({
  branchId,
  initialData,
}: {
  branchId?: Scalars['ID'];
  initialData?: Array<Category>;
}) => QueryHookResponse<Array<Category>>;

const useCategories: useCategoriesTypes = ({ branchId, initialData }) =>
  useQuery<Array<Category>>({
    query: storeCategoriesQuery,
    variables: { subdomain: ServiceConfigs.getSubDomain(), branchId },
    handler: (res: any) => res.categories,
    config: { initialData },
  });

export default useCategories;
