import React, { ReactChild } from 'react';

const IcLocation: () => ReactChild = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_16349" data-name="Group 16349" transform="translate(13134 9654)">
      <rect
        id="Rectangle_6072"
        data-name="Rectangle 6072"
        width="24"
        height="24"
        transform="translate(-13134 -9654)"
        fill="none"
      />
      <g id="Group_16330" data-name="Group 16330" transform="translate(-13134 -9654.243)">
        <path
          id="Path_8597"
          data-name="Path 8597"
          d="M12,22.236a2.73,2.73,0,0,1-1.944-.806L5.813,17.187a8.75,8.75,0,1,1,12.375,0L13.944,21.43A2.73,2.73,0,0,1,12,22.236ZM12,3.75A7.25,7.25,0,0,0,6.873,16.127l4.244,4.243a1.248,1.248,0,0,0,1.766,0l4.243-4.243A7.25,7.25,0,0,0,12,3.75Z"
        />
        <path
          id="Path_8598"
          data-name="Path 8598"
          d="M12,7.25A3.75,3.75,0,1,1,8.25,11,3.754,3.754,0,0,1,12,7.25Zm0,6A2.25,2.25,0,1,0,9.75,11,2.253,2.253,0,0,0,12,13.25Z"
        />
      </g>
    </g>
  </svg>
);

export default IcLocation;
