import React from 'react';

const IcTwitter: React.FC = () => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4375 1.84962C16.8045 2.12466 16.1347 2.30587 15.4494 2.38751C16.1696 1.96576 16.7113 1.29575 16.9727 0.503131C16.2918 0.901308 15.548 1.18069 14.7734 1.3293C14.4471 0.987419 14.0548 0.715441 13.6202 0.529901C13.1856 0.34436 12.7178 0.249133 12.2453 0.250006C10.3321 0.250006 8.78379 1.77579 8.78379 3.65665C8.78243 3.91827 8.81241 4.17911 8.87309 4.4336C7.50118 4.36929 6.1578 4.01931 4.9289 3.40607C3.7 2.79283 2.61263 1.92983 1.73637 0.872271C1.42895 1.39052 1.26637 1.98182 1.26562 2.58438C1.26562 3.76563 1.88191 4.80977 2.8125 5.42149C2.26115 5.40841 1.72111 5.26248 1.2382 4.9961V5.03829C1.2382 6.69063 2.43352 8.06524 4.01555 8.37813C3.71805 8.45744 3.41148 8.49762 3.10359 8.49766C2.88513 8.49804 2.66715 8.47685 2.45285 8.43438C2.89266 9.7879 4.1727 10.7723 5.68898 10.8004C4.45689 11.7499 2.9442 12.2632 1.38867 12.2594C1.11256 12.259 0.836707 12.2425 0.5625 12.2102C2.14495 13.2206 3.98441 13.7551 5.86195 13.75C12.2379 13.75 15.7212 8.55743 15.7212 4.05391C15.7212 3.90626 15.7173 3.7586 15.7103 3.61446C16.3864 3.13354 16.9713 2.5359 17.4375 1.84962Z"
      fill="black"
    />
  </svg>
);
export default IcTwitter;
