import { gql } from 'graphql-request';

const storeLinksQuery = gql`
  query($subdomain: String!) {
    store(subdomain: $subdomain) {
      id
      links {
        id
        body
        linkType
        titleAr
        titleEn
        url
      }
    }
  }
`;

export default storeLinksQuery;
