import React from 'react';

const IcFacebook: React.FC = props => (
  <svg width={10} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M6.397 17.997V9.8h2.765l.41-3.21H6.398V4.549c0-.926.258-1.56 1.587-1.56h1.684V.127A22.336 22.336 0 0 0 7.2 0C4.757 0 3.079 1.492 3.079 4.23v2.356H.332v3.209h2.753v8.202h3.312Z"
      fill="#000"
    />
  </svg>
);
export default IcFacebook;
