import React from 'react';
import { Search, ShimmerComponent, useTheme } from '@zydalabs/storefront-components';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { Typography, useTheme as useZacTheme } from '@zydalabs/zac-react';

import OrderMode from 'modules/productsModule/components/orderMode';
import { StyledAlignItemsCenter, StyledPageWrapper } from 'common/styles';
import { NextImage, Footer } from 'common/components';
import { RenderingRegistry } from 'common/lib/registries';
import { changeRoute } from 'common/utils';
import { useIsMobile, useResponsive } from 'common/hooks';
import CashbackSection from 'common/components/CashbackSection';
import ServiceConfigs from 'service/config';
import FixedButton from 'modules/productsModule/components/FixedButton';
import Categories from '../../components/categories/Categories';
import {
  StyledOrderModeWrapper,
  StoreBrandInfoMobile,
  StoreBrandInfoMobileWrapper,
  StyledLogoImageWrapper,
  StyledSearchWrapper,
} from './style';
import { LANGUAGE_AR, URLS } from '../../../../common/constants';
import { useLayout } from '../../../../service/hooks';
import StoreBrandInfoMobileLoading from './StoreBrandInfoMobileLoading';

const Vouchers = dynamic(() => import('modules/productsModule/components/Vouchers'));
const RecentOrders = dynamic(() => import('modules/ordersModule/components/RecentOrders'), { ssr: false });

const HomePage: React.FC = () => {
  const theme = useTheme();
  const isLargeScreen = useResponsive(`min-width: ${theme.screenBreakpoints.extraLarge}`);
  const { t, lang } = useTranslation('search');
  const isArabic = lang === LANGUAGE_AR;
  const { data: layoutSettings, isLoading } = useLayout();
  const isManualOrder = RenderingRegistry.getIsManualOrder();
  const isMobile = useIsMobile();
  const {
    logoUrl,
    id,
    titleAr,
    titleEn,
    descriptionAr,
    descriptionEn,
    defaultProductPrepTime,
    hideStoreName,
    activeStorefrontCashbackProgram,
    branchesCount,
  } = layoutSettings || {};
  const showVouchers = RenderingRegistry.getShowVouchers();
  const showReordering = RenderingRegistry.getShowReordering();

  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();

  const descriptionText = isArabic ? descriptionAr : descriptionEn;
  const titleText = isArabic ? titleAr : titleEn;
  const { logoRounded } = RenderingRegistry.getShowLayout() || {};

  const shouldCenterImage = hideStoreName && !descriptionText;
  const { colors }: any = useZacTheme();
  return (
    <StyledPageWrapper id="scrollable-content">
      {!!activeStorefrontCashbackProgram && !isMobile && <CashbackSection />}
      <div style={{ background: colors?.positiveAccent?.background }}>
        <StyledOrderModeWrapper>
          {isLoading ? (
            <StoreBrandInfoMobileLoading />
          ) : (
            <StoreBrandInfoMobile centerImage={shouldCenterImage} logoUrl={logoUrl}>
              {!shouldCenterImage && (
                <StoreBrandInfoMobileWrapper logoUrl={logoUrl}>
                  {!hideStoreName && (
                    <Typography
                      variant={isLargeScreen ? 'headingExpressive32' : 'headingExpressive20'}
                      m={0}
                      testId={`${id}_store_name`}
                    >
                      {titleText}
                    </Typography>
                  )}
                  <Typography variant="body16" m={0} color={theme.color.headlineText} testId={`${id}_store_slogan`}>
                    {descriptionText}
                  </Typography>
                </StoreBrandInfoMobileWrapper>
              )}
              {logoUrl && (
                <StyledLogoImageWrapper hideStoreName={shouldCenterImage} logoRounded={logoRounded}>
                  <NextImage
                    src={logoUrl}
                    layout="fill"
                    objectFit="cover"
                    data-testid="store_logo"
                    priority
                    type="cover"
                    alt={`${titleEn} store logo`}
                  />
                </StyledLogoImageWrapper>
              )}
            </StoreBrandInfoMobile>
          )}
          {(!useDeliveryzonesMs || !isMobile || isManualOrder) && <OrderMode />}
        </StyledOrderModeWrapper>

        {showVouchers && <Vouchers />}
        {showReordering && <RecentOrders />}

        {isManualOrder && (
          <StyledSearchWrapper>
            {isLoading ? (
              <ShimmerComponent.ShimmerEffect>
                <ShimmerComponent.Rect
                  height={40}
                  width="100%"
                  margin={`${theme.space[4]}px 0 0 0`}
                  borderRadius={`${theme.space[1]}px`}
                />
              </ShimmerComponent.ShimmerEffect>
            ) : (
              <Search
                key="search-input"
                onClick={() => changeRoute(URLS.SEARCH)}
                placeholder={t('search')}
                mt={theme.space[1]}
                width="auto"
                data-testid="home-search-product"
                grayed
              />
            )}
          </StyledSearchWrapper>
        )}

        <Categories isLoading={isLoading} defaultPrepTime={defaultProductPrepTime} />
        <StyledAlignItemsCenter>
          <Footer storeName={titleText} branchesCount={branchesCount} />
        </StyledAlignItemsCenter>
        <FixedButton />
      </div>
    </StyledPageWrapper>
  );
};

export default HomePage;
