import styled from 'styled-components';

export const StyledGroceryCategoryProductsWrapper = styled.div`
  display: flex;
  margin-top: 0;
  overflow-x: scroll;
  grid-template-columns: repeat(2, 1fr);
  padding: ${({ theme }) => theme.space[4]}px;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
  > div:not(:last-child) {
    margin-inline-end: ${({ theme }) => theme.space[3]}px;
  }
`;

export const StyledGroceryProductsContainer = styled.div`
  padding: ${({ theme }) => `${theme.space[2]}px 0 ${theme.space[4]}px`};
  border-bottom: 1px solid ${({ theme }) => theme.color.background};
`;

export const StyledGroceryCategory = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding-bottom: ${({ theme }) => `${theme.space[4]}px`};
  margin: 0 ${({ theme }) => theme.space[4]}px;
  & h1 {
    line-height: 24px;
    margin: 0;
  }
`;

export const StyledGroceryCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledViewProductsBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
`;

export const StyledGroceryCardRow = styled.div`
  display: flex;
  overflow: hidden;
`;
