import styled from 'styled-components';

export const StyledLinkWrapper = styled.a`
  text-decoration: none;
  margin: ${props => props.theme.space[2]}px 0;
`;

export const StyledFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  alignitems: center;
  flex: 1;
  margin: 0 ${props => props.theme.space[4]}px;
`;

export const StyledRowWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[3]}px 0;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.color.grey1};

  &:nth-of-type(2) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grey1};
  }
`;

export const StyledPoweredByZyda = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) => theme.space[3]}px 0;
  justify-content: center;
`;
