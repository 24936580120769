import styled from 'styled-components';

export const StyledEmojiWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCashBackWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  ${({ theme }) => `
  background:linear-gradient(to right, ${theme.color.primary20}, ${theme.color.primary10});
  padding: ${theme.space[4]}px ${theme.space[6]}px ${theme.space[5]}px ${theme.space[4]}px;
  `}
`;

export const StyledCashBackTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline-end: ${({ theme }) => theme.space[4]}px;
`;
