import { gql } from 'graphql-request';

const storeCategoriesQuery = gql`
  query ($subdomain: String!, $branchId: ID) {
    categories(subdomain: $subdomain, branchId: $branchId) {
      titleEn
      titleAr
      uuid
      published
      position
      photoUrl
      showCategory
    }
  }
`;

export default storeCategoriesQuery;
