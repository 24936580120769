import React from 'react';
import { Icon, useTheme } from '@zydalabs/storefront-components';
import { Typography } from '@zydalabs/zac-react';
import useTranslation from 'next-translate/useTranslation';
import { useStoreTabs } from 'common/hooks';
import { StyledFooterWrapper, StyledLinkWrapper, StyledPoweredByZyda, StyledRowWrapper } from './styles';
import { useLinks } from '../../../service/hooks';
import { changeRoute, getFullStoreURL } from '../../utils';
import { StyledAnchor, StyledLink, StyledList } from '../Layout/components/SideMenu/style';
import { IcChevronRight, IcFacebook, IcInstagram, IcSnapchat, IcTwitter, IcWhatsapp } from '../../icons';
import { PoweredByZyda } from '../index';
import ServiceConfigs from '../../../service/config';

const Footer = ({ storeName, branchesCount }: { storeName: string; branchesCount: number }) => {
  const theme = useTheme();
  const { t } = useTranslation('home');
  const { data } = useLinks();
  const { storeLinks } = data || {};
  const { socialLinks } = storeLinks || {};

  const iconsMap = {
    facebook: IcFacebook,
    instagram: IcInstagram,
    whatsapp: IcWhatsapp,
    snapchat: IcSnapchat,
    twitter: IcTwitter,
  };

  const storeBaseUrl = getFullStoreURL();

  const navLinks = useStoreTabs(branchesCount);

  const handleOnRouteChange = async newRoute => {
    await changeRoute(newRoute);
  };

  return (
    <StyledFooterWrapper>
      <StyledRowWrapper>
        <Typography variant="heading16">{storeName}</Typography>
        <div style={{ display: 'flex' }}>
          {socialLinks?.map(socialLink => (
            <StyledAnchor key={socialLink.id} href={socialLink.url} target="_blank" rel="noopener noreferrer">
              <Icon icon={iconsMap[socialLink.linkType]} mx={2} />
            </StyledAnchor>
          ))}
        </div>
      </StyledRowWrapper>

      <StyledList>
        {navLinks.map(navLink => (
          <li key={navLink.value} onClick={() => handleOnRouteChange(navLink.url)}>
            <StyledLink>
              {navLink.icon}
              <Typography variant="heading16">{navLink.title}</Typography>
            </StyledLink>
            <Icon icon={IcChevronRight} reverseOnRtl />
          </li>
        ))}
      </StyledList>

      {!ServiceConfigs.getOrderFastStore() && (
        <StyledRowWrapper>
          <StyledLinkWrapper href={`${storeBaseUrl}/privacy-policy`} target="_blank" rel="noreferrer">
            <Typography variant="body14" color={theme.color.grey0} cursor="pointer">
              {t('privacyPolicy')}
            </Typography>
          </StyledLinkWrapper>
          <StyledLinkWrapper href={`${storeBaseUrl}/return-policy`} target="_blank" rel="noreferrer">
            <Typography variant="body14" color={theme.color.grey0} cursor="pointer">
              {t('shippingPolicy')}
            </Typography>
          </StyledLinkWrapper>
        </StyledRowWrapper>
      )}

      <StyledPoweredByZyda>
        <PoweredByZyda />
      </StyledPoweredByZyda>
    </StyledFooterWrapper>
  );
};

export default Footer;
