import { gql } from 'graphql-request';

const showAddressQuery = gql`
  query ($addressId: ID!, $subdomain: String) {
    showAddress(addressId: $addressId, subdomain: $subdomain) {
      id
      userId
      title
      street
      block
      areaName
      cityName
      building
      unitType
      unitNo
      covered
      areaId
      phoneNumber
      avenue
      floor
      notes
      lat
      lng
      dzmsVerified
      area {
        id
        lat
        lng
        titleAr
        titleEn
        cityId
        deliveryZone {
          branchId
          id
          areaId
          minimumOrder
          deliveryTime
        }
      }
    }
  }
`;
export default showAddressQuery;
